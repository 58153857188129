import { get, post, put } from './client'
import { auth } from '../../firebaseConfig'

const getProfile = async (userId) => {
	return await get(`users/${userId}`)
}

const createProfile = async (body) => {
	return await post('users', {
		body
	})
}

const updateProfile = async (body) => {
	return await put('users', {
		body
	})
}

const syncCrm = async ({ name, email, groupMemberId, emailId }) => {
	return await post('users/sync-crm', {
		body: { name, email, groupMemberId, emailId }
	})
}

export { getProfile, createProfile, updateProfile, syncCrm }
